import { getChannelPref } from '@/i18n/userChannel';
import gqlClient from '../gql';
import { GET_MENU_FROM_PAGE, pagesVariables } from '../graphql';
import { Channel, Menu } from '../model';
import { onResponseError } from '@/utils/sentry/onResponseError';
import { onResponseCatchError } from '@/utils/sentry/onResponseCatchError';

export const getHomeMenu = async (
  channel?: Channel,
): Promise<Menu | undefined> => {
  try {
    let channelId = channel?.id;
    let languageCode = channel?.languageCode;
    if (!channelId) {
      const { channelPref } = await getChannelPref();
      channelId = channelPref.id;
      languageCode = channelPref.languageCode;
    }

    const pageResponse = await gqlClient.query<any>({
      query: GET_MENU_FROM_PAGE,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: pagesVariables({
        pageType: 'page_home',
        channelId,
        languageCode,
      }),
    });

    const pageData = pageResponse?.data;

    onResponseError(pageResponse);

    return pageData?.Pages.docs?.[0]?.menu;
  } catch (error) {
    onResponseCatchError(error);
  }
};
