import { gql } from '@apollo/client';
import { DocumentPage } from '../model';
import { cropMediaFragment } from '@/utils/graphql/cropMediaFragment';

export const liveCoverageVariables = (
  props: DocumentPage,
  channel?: string,
) => {
  return {
    where: {
      channel: {
        equals: channel,
      },
    },
    ...props,
  };
};
export const liveCoveragesVariables = (
  props: DocumentPage,
  channel?: string,
) => {
  return {
    where: {
      channel: {
        equals: channel,
      },
    },
    ...props,
  };
};

export const GET_LIVE_COVERAGE_BY_ID = gql`
  query GetLiveCoverage($id: String!, $locale: LocaleInputType) {
    LiveCoverage(id: $id, locale: $locale) {
      id
      title
      description
      updatedAt
      createdAt
      details
      mainHeadline
      summary
      media {
        caption
        ${cropMediaFragment}
      }
    }
  }
`;

export const GET_LIVE_COVERAGES = gql`
  query GetLiveCoverages($where: LiveCoverage_where, $limit: Int, $page: Int) {
    LiveCoverages(where: $where, limit: $limit, page: $page) {
      docs {
        id
        title
        description
        updatedAt
        createdAt
        details
        mainHeadline
        media {
          caption
          ${cropMediaFragment}
         }
      }
      hasNextPage
      hasPrevPage
      limit
      nextPage
      offset
      page
      pagingCounter
      prevPage
      totalDocs
      totalPages
    }
  }
`;
