import gqlClient from '../gql';
import { GET_BREAKING_NEWS, breakingNewsVariables } from '../graphql';
import {
  BreakingNew,
  BreakingNewResponse,
  BreakingNewThread,
  Content,
} from '../model';
import { onResponseError } from '@/utils/sentry/onResponseError';
import { onResponseCatchError } from '@/utils/sentry/onResponseCatchError';

export const getBreakingNews = async (
  channelId: string,
): Promise<BreakingNew[] | undefined> => {
  try {
    // @ts-ignore
    const breakingNewResponse = await gqlClient.query<BreakingNewResponse>({
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      query: GET_BREAKING_NEWS,
      variables: breakingNewsVariables(channelId),
    });
    onResponseError(breakingNewResponse);
    const allNews: BreakingNew[] = [];

    breakingNewResponse?.data?.BreakingNews.docs.forEach(news => {
      allNews.push(news);
      if (news.threads && news.threads.length > 0) {
        news.threads.forEach((thread, index) => {
          allNews.push({
            id: thread.id,
            title: thread.title,
            description: thread.description,
            twitterId: thread.twitterId,
            updatedAt: thread.date,
            createdAt: thread.date,
            status: news.status,
            isPinned: index < 3 && news.isPinned,
          } as BreakingNew);
        });
      }
    });
    return allNews;
  } catch (error) {
    onResponseCatchError(error);
  }
};

export const getContentsFromBreakingNews = (
  breakingNews?: BreakingNew[],
): Content[] | undefined => {
  return breakingNews
    ?.filter(breakingNew => !!breakingNew.threads?.length)
    .reduce((acc, breakingNew) => {
      return [...acc, ...getContentsFromThreads(breakingNew.threads ?? [])];
    }, [] as Content[]);
};

export const getContentsFromThreads = (
  threads: BreakingNewThread[],
): Content[] => {
  return threads?.map(thread => thread.content! as Content);
};
