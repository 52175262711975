import * as Sentry from '@sentry/nextjs';
import { ignoreGraphqlSchemaError } from './ignoreGraphqlSchemaError';

export function onResponseCatchError(graphqlError: any) {
  if ((graphqlError as any)?.cause?.result?.errors?.length) {
    const anyGraphqlSchemaErrorMessages = ignoreGraphqlSchemaError(
      (graphqlError as any).cause.result.errors,
    );
    if (!anyGraphqlSchemaErrorMessages) {
      Sentry.captureException(graphqlError);
    }
  } else {
    Sentry.captureException(graphqlError);
  }
}
