import { ApolloClient, HttpLink, InMemoryCache, makeVar } from '@apollo/client';
import { LRUCache } from 'lru-cache';

export const cacheExpiry = makeVar<{ [key: string]: number }>({});

export const cache = new LRUCache({
  max: 5000,
  ttl: 20 * 1000,
});

const gqlClient = new ApolloClient({
  cache: new InMemoryCache({}),
  link: new HttpLink({
    uri: `${process.env.NEXT_PUBLIC_PAYLOAD_API_URL}`,
  }),
});

export default gqlClient;
