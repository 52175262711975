import { gql } from '@apollo/client';
import { Channel } from '../model';
import { menuFragment } from '@/utils/graphql/menuFragment';

export type MenuVariables = { channel: Channel };
export const menuVariables = ({ channel }: MenuVariables) => {
  return {
    where: {
      channel: { equals: channel.id },
    },
  };
};

export const GET_MENU_FROM_PAGE = gql`
  query GetAllPages($where: Page_where, $locale: LocaleInputType) {
    Pages(where: $where, locale: $locale) {
      docs {
        ${menuFragment}
      }
    }
  }
`;
