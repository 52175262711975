import * as Sentry from '@sentry/nextjs';
import { ignoreGraphqlSchemaError } from './ignoreGraphqlSchemaError';

export function onResponseError(response: any) {
  if (response.error && response.errors?.length) {
    const anyGraphqlSchemaErrorMessages = ignoreGraphqlSchemaError(
      response.errors as any,
    );
    if (!anyGraphqlSchemaErrorMessages) {
      Sentry.captureException(response.error ?? response.errors);
    }
  }
}
