import { getAPILocale } from '@/utils';
import { gql } from '@apollo/client';

export const authorVariables = (id?: string, languageCode: string = 'en') => {
  return {
    id,
    locale: getAPILocale(languageCode),
  };
};

export const GET_AUTHOR = gql`
  query GetAuthor($id: String!, $locale: LocaleInputType) {
    Author(id: $id, locale: $locale) {
      id
      name
      bio
      profileUrl
      profilePicture {
        url
        type
        caption
        mimeType
        playlistJson
        thumbnailUrl
      }
    }
  }
`;
