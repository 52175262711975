export const menuFragment = `menu {
        primaryMenuItems {
          name
          slug
          linkType
          url
          content {
            id
            title
            type
            slug
          }
          liveCoverage{
              id
          }
          page {
            slug
          }
            topic{
            page {
            slug
            }
            }
          id
          description
          menuItems {
            name
            slug
            linkType
            url
            content {
              id
              title
              type
              slug
            }
            liveCoverage{
                id
            }
            page {
              slug
            }
            id
            description
          }
        }
        secondaryMenuItems {
          name
          slug
          linkType
          url
          content {
            id
            title
            type
            slug
          }
          liveCoverage{
              id
          }
          page {
            slug
          }
            topic{
            page {
            slug
            }
            }
          id
          description
          menuItems {
            name
            slug
            linkType
            url
            content {
              id
              title
              type
              slug
            }
            liveCoverage{
                id
            }
            page {
              slug
            }
            id
            description
          }
        }
        title
}`;
