import { gql } from '@apollo/client';

export const GET_ALL_CATEGORIES = gql`
  query Categories {
    Categories {
      docs {
        title
        updatedAt
        id
      }

      hasNextPage
      hasPrevPage
      limit
      nextPage
      offset
      page
      pagingCounter
      prevPage
      totalDocs
      totalPages
    }
  }
`;
