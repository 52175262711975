import gqlClient, { cache } from '../gql';
import {
  GET_LIVE_COVERAGE_BY_ID,
  GET_LIVE_COVERAGES,
  liveCoverageVariables,
} from '../graphql';
import { Channel, LiveCoverageResponse, LiveCoveragesResponse } from '../model';
import { onResponseError } from '@/utils/sentry/onResponseError';
import { onResponseCatchError } from '@/utils/sentry/onResponseCatchError';
import { getAPILocale } from '@/utils';

export const getLiveCoverage = async (
  props: any,
  channel?: string,
): Promise<LiveCoverageResponse['LiveCoverage'] | undefined> => {
  try {
    const variables = liveCoverageVariables(props, channel);

    const key = JSON.stringify(variables);
    if (cache.has(key)) {
      return cache.get(key) as LiveCoverageResponse['LiveCoverage'];
    }
    const liveCoverageResponse = await gqlClient.query<LiveCoverageResponse>({
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      query: GET_LIVE_COVERAGE_BY_ID,
      variables: liveCoverageVariables(props, channel),
    });
    onResponseError(liveCoverageResponse);
    if (liveCoverageResponse?.data?.LiveCoverage) {
      cache.set(key, liveCoverageResponse.data?.LiveCoverage);
    }

    return liveCoverageResponse?.data?.LiveCoverage;
  } catch (error) {
    onResponseCatchError(error);
  }
};

export const getAllLiveCoverages = async ({
  limit,
  channel,
  where = {},
}: {
  limit?: number;
  channel?: Channel;
  where?: any;
}): Promise<LiveCoveragesResponse | undefined> => {
  try {
    const liveCoverageResponse = await gqlClient.query<LiveCoveragesResponse>({
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      query: GET_LIVE_COVERAGES,
      variables: {
        limit: limit || 1,
        locale: getAPILocale(channel?.languageCode),
        where: {
          channel: { equals: channel?.id },
          ...where,
        },
      },
    });
    onResponseError(liveCoverageResponse);

    return liveCoverageResponse?.data;
  } catch (error) {
    onResponseCatchError(error);
  }
};
