import { gql } from '@apollo/client';
import { Channel } from '../model';

export type TopicVariable = { channel: Channel; page?: number; limit?: number };

export const topicVariables = ({
  channel,
  page,
  limit = 1000,
}: TopicVariable) => {
  return {
    where: {
      channel: { equals: channel.id },
    },
    page,
    limit,
  };
};

export const GET_ALL_PAGES = gql`
  query GetAllPages($where: Page_where, $limit: Int, $page: Int) {
    Pages(where: $where, limit: $limit, page: $page) {
      docs {
        id
        slug
        pageLayoutIdentifier
      }
      limit
      page
      totalDocs
      totalPages
    }
  }
`;

export const GET_ALL_TOPICS = gql`
  query GetAllTopics($where: Topic_where, $limit: Int) {
    Topics(where: $where, limit: $limit) {
      docs {
        id
        title
        updatedAt
        createdAt
      }
      hasNextPage
      hasPrevPage
      limit
      nextPage
      offset
      page
      pagingCounter
      prevPage
      totalDocs
      totalPages
    }
  }
`;
