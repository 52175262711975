import gqlClient, { cache } from '../gql';
import {
  contentsArticleSiteMapVariables,
  contentsAuthorVariables,
  contentsLoadMoreVariables,
  contentsVariables,
  GET_CONTENTS_SOFT_POPULATE,
  GET_FULL_CONTENTS,
  GET_SITE_MAP_ARTICLECONTENTS,
} from '../graphql';
import { Content, ContentResponse } from '../model';
import { onResponseError } from '@/utils/sentry/onResponseError';
import { onResponseCatchError } from '@/utils/sentry/onResponseCatchError';

export const getContent = async (
  slug: string,
  languageCode?: string,
  draft?: boolean,
): Promise<Content | undefined> => {
  try {
    const variables = contentsVariables(slug, languageCode, undefined, draft);
    const key = JSON.stringify(variables);
    if (cache.has(key)) {
      return cache.get(key) as Content;
    }

    const contentResponse = await gqlClient.query<ContentResponse>({
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      query: GET_FULL_CONTENTS,
      variables,
    });
    onResponseError(contentResponse);
    if (contentResponse.data.Contents.docs[0]) {
      cache.set(key, contentResponse.data.Contents.docs[0]);
    }
    return contentResponse.data.Contents.docs[0];
  } catch (error) {
    onResponseCatchError(error);
  }
};

export const getContents = async ({
  where = {},
  locale,
  limit,
}: {
  where: any;
  locale?: string;
  limit?: number;
}): Promise<Content[] | undefined> => {
  try {
    const contentResponse = await gqlClient.query<ContentResponse>({
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      query: GET_FULL_CONTENTS,
      variables: { where, limit, locale },
    });
    onResponseError(contentResponse);

    return contentResponse?.data?.Contents.docs;
  } catch (error) {
    onResponseCatchError(error);
  }
};

export const getSoftPopulateContents = async ({
  where = {},
  locale,
  limit,
}: {
  where: any;
  locale?: string;
  limit?: number;
}): Promise<Content[] | undefined> => {
  try {
    const contentResponse = await gqlClient.query<ContentResponse>({
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      query: GET_CONTENTS_SOFT_POPULATE,
      variables: { where, limit, locale },
    });
    onResponseError(contentResponse);

    return contentResponse?.data?.Contents.docs;
  } catch (error) {
    onResponseCatchError(error);
  }
};

export const getLoadMoreContents = async (filters: {
  existingIds: string[];
  languageCode: string;
  channelId: string;
  page: number;
  primaryTopicId?: string;
}): Promise<ContentResponse | undefined> => {
  try {
    const contentResponse = await gqlClient.query<ContentResponse>({
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      query: GET_FULL_CONTENTS,
      variables: contentsLoadMoreVariables(filters),
    });
    onResponseError(contentResponse);

    return contentResponse?.data;
  } catch (error) {
    onResponseCatchError(error);
  }
};

export const getSiteMapArticleContents = async (
  where: any = {},
  languageCode?: string,
  page?: number,
  limit?: number,
): Promise<ContentResponse['Contents'] | undefined> => {
  try {
    const contentResponse = await gqlClient.query<ContentResponse>({
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      query: GET_SITE_MAP_ARTICLECONTENTS,
      variables: contentsArticleSiteMapVariables(
        where,
        languageCode,
        page,
        false,
        limit,
      ),
    });
    onResponseError(contentResponse);

    return contentResponse?.data?.Contents;
  } catch (error) {
    onResponseCatchError(error);
  }
};

export const getAuthorContentsPageable = async (
  author: any = '',
  languageCode?: string,
  page?: number,
): Promise<ContentResponse | undefined> => {
  try {
    const contentResponse = await gqlClient.query<ContentResponse>({
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      query: GET_FULL_CONTENTS,
      variables: contentsAuthorVariables(author, languageCode, page),
    });
    onResponseError(contentResponse);

    return contentResponse?.data;
  } catch (error) {
    onResponseCatchError(error);
  }
};
