import { gql } from '@apollo/client';

export const breakingNewsVariables = (channelId: string) => {
  return {
    where: {
      channel: {
        equals: channelId,
      },
      status: {
        in: ['published', 'changed'],
      },
    },
  };
};

export const GET_BREAKING_NEWS = gql`
  query GetBreakingNews($where: BreakingNew_where, $limit: Int) {
    BreakingNews(where: $where, limit: $limit) {
      docs {
        id
        title
        description
        twitterId
        updatedAt
        isPinned
        threads {
          id
          date
          title
          description
          twitterId
          content {
            title
            description
            slug
            type
            subType
            readingTime {
              text
            }
          }
        }
        topics {
          title
          updatedAt
        }
      }
      hasNextPage
      hasPrevPage
      limit
      nextPage
      offset
      page
      pagingCounter
      prevPage
      totalDocs
      totalPages
    }
  }
`;
