import { gql } from '@apollo/client';

export const channelsVariables = () => {
  return {
    limit: 50,
    sort: 'channelPriority',
    where: {},
  };
};

export const channelVariables = (id?: string) => {
  return {
    id,
  };
};

export const GET_CHANNELS = gql`
  query GetChannels($where: Channel_where, $limit: Int, $sort: String) {
    Channels(where: $where, limit: $limit, sort: $sort) {
      docs {
        id
        name
        description
        languageCode
        websiteLogo {
          url
        }
        streamingOptions {
          active
          liveStream {
            type
            title
            epgUrl
            description
            streamUrl
          }
        }
      }
      hasNextPage
      hasPrevPage
      limit
      nextPage
      offset
      page
      pagingCounter
      prevPage
      totalDocs
      totalPages
    }
  }
`;
