import * as Sentry from '@sentry/nextjs';
import gqlClient from '../gql';
import { channelsVariables, GET_CHANNELS } from '../graphql';
import { Channel, ChannelResponse } from '../model';
import { onResponseError } from '@/utils/sentry/onResponseError';
import { onResponseCatchError } from '@/utils/sentry/onResponseCatchError';

export const getChannel = async (): Promise<Channel | undefined> => {
  try {
    const channelResponse = await gqlClient.query<ChannelResponse>({
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      query: GET_CHANNELS,
      variables: channelsVariables(),
    });

    const channelData = channelResponse?.data;
    const channel = channelData?.Channels.docs.find(
      channel => channel.languageCode === 'en',
    );
    onResponseError(channelResponse);

    return channel;
  } catch (error) {
    onResponseCatchError(error);
  }
};

export const getChannels = async (limit = 50): Promise<Channel[]> => {
  const channelResponse = await gqlClient.query<ChannelResponse>({
    query: GET_CHANNELS,
    errorPolicy: 'all',
    variables: {
      limit,
      sort: 'channelPriority',
    },
  });

  const channelData = channelResponse?.data;
  const channel = channelData?.Channels?.docs?.filter(
    x => x.languageCode !== 'tr',
  );
  if (channelResponse.error || channelResponse.errors?.length) {
    Sentry.captureException(channelResponse.error ?? channelResponse.errors);
  }
  return channel;
};
